<template>
  <div class="list_body">
    <a-breadcrumb style="margin-bottom: 10px">
      <a-breadcrumb-item> 位置:</a-breadcrumb-item>
      <a-breadcrumb-item>一级页面</a-breadcrumb-item>
      <a-breadcrumb-item><a href="">二级页面</a></a-breadcrumb-item>
    </a-breadcrumb>
    <div :style="{ padding: '15px', background: '#fff', minHeight: '5vh', display: 'flex' }">
      <!-- 搜索 -->
      <a-form-model layout="inline" @submit="searchSubmit">
        <a-form-model-item label="创建日期:">
          <a-range-picker valueFormat="yyyy-MM-DD HH:mm:ss" v-model="createdRange" />
        </a-form-model-item>
        <a-form-model-item label="更新日期:">
          <a-range-picker valueFormat="yyyy-MM-DD HH:mm:ss" v-model="updatedRange" />
        </a-form-model-item>
        <a-form-model-item label="状态:">
          <a-select style="width: 120px" v-model="searchForm.status" :allowClear="true">
            <a-select-option value="上架"> 上架 </a-select-option>
            <a-select-option value="下架"> 下架 </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="上架日期:">
          <a-range-picker valueFormat="yyyy-MM-DD HH:mm:ss" v-model="publishDateRange" />
        </a-form-model-item>
        <a-form-model-item label="下架日期:">
          <a-range-picker valueFormat="yyyy-MM-DD HH:mm:ss" v-model="unPublishDateRange" />
        </a-form-model-item>
        <a-form-model-item label="位置:" :allowClear="true">
          <a-input v-model="searchForm.location" placeholder="请输入" style="width: 120px" />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" html-type="submit">查询</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div :style="{ padding: '10px' }"></div>
    <div :style="{ padding: '24px', background: '#fff', minHeight: '90vh' }">
      <a-space>
        <a-button type="primary" @click="showModal"> 新增banner </a-button>
        <a-button type="primary" @click="shelve"> 批量上架 </a-button>
        <a-button type="primary" @click="unShelve"> 批量下架 </a-button>
      </a-space>
      <!-- 新增/修改 -->
      <a-modal title="新增/修改Banner" :visible="visible" :confirm-loading="confirmLoading" @ok="addOk" @cancel="handleCancel" :mask-closable="false">
        <a-form-model layout="vertical" labelAlign="right" :label-col="{ span: 4 }" :wrapper-col="{ span: 14 }">
          <a-form-model-item label="选择位置:">
            <a-select :default-value="banner.location" style="width: 120px" @change="locationHandleChange">
              <a-select-option v-for="(name,index) in locationNames" :key="index" :value="name"> {{name}} </a-select-option>
            </a-select>
            <!-- <a-input v-model="banner.location" placeholder="请输入" style="width: 220px" /> -->
          </a-form-model-item>
          <a-form-model-item label="banner图:">
            <upload-widget v-model="banner.cover"></upload-widget>
          </a-form-model-item>
          <a-form-model-item label="链接地址:">
            <a-input placeholder="请输入链接地址" style="width: 220px" v-model="banner.linkAddress" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
      <!-- 表格 -->
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="datapager"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        @change="handleTableChange"
        :row-key="(record) => record.id"
      >
        <span slot="location" slot-scope="text">{{ text }}</span>
        <img :style="{ width: '90px' }" slot="cover" slot-scope="text" :src="serverUrl + text" alt="" />
        <span slot="linkAddress" slot-scope="text">{{ text }}</span>
        <span slot="created" slot-scope="text">{{ text }}</span>
        <span slot="updated" slot-scope="text">{{ text }}</span>
        <span slot="status_on" slot-scope="text">{{ text }}</span>
        <span slot="status_off" slot-scope="text">{{ text }}</span>
        <span slot="status" slot-scope="text">{{ text }}</span>
        <span slot="action" slot-scope="text, record">
          <span style="color: #2e71fe; font-size: 10px; font-weight: 30px" @click="showUpdate(record)">编辑</span>
          <a-divider type="vertical" />
          <span style="color: #2e71fe; font-size: 10px; font-weight: 30px" @click="shelve(record)" v-if="record.status == '下架'"> 上架 </span>
          <span style="color: #2e71fe; font-size: 10px; font-weight: 30px" @click="unShelve(record)" v-if="record.status == '上架'"> 下架 </span>
          <a-divider type="vertical" />
          <span style="color: #2e71fe; font-size: 10px; font-weight: 30px" @click="del(record)"> 删除 </span>
        </span>
      </a-table>
    </div>
  </div>
</template>

<script>
/**表头信息 */
const columns = [
  {
    title: '位置',
    dataIndex: 'location',
    key: 'location',
    scopedSlots: { customRender: 'location' },
  },
  {
    title: 'Banner',
    dataIndex: 'cover',
    key: 'cover',
    scopedSlots: { customRender: 'cover' },
  },
  {
    title: '链接地址',
    dataIndex: 'linkAddress',
    key: 'linkAddress',
    scopedSlots: { customRender: 'linkAddress' },
  },
  {
    title: '创建日期',
    dataIndex: 'created',
    key: 'created',
    scopedSlots: { customRender: 'created' },
  },
  {
    title: '更新日期',
    key: 'updated',
    dataIndex: 'updated',
    scopedSlots: { customRender: 'updated' },
  },
  {
    title: '上架日期',
    key: 'status_on',
    dataIndex: 'status_on',
    scopedSlots: { customRender: 'status_on' },
  },
  {
    title: '下架日期',
    key: 'status_off',
    dataIndex: 'status_off',
    scopedSlots: { customRender: 'status_off' },
  },
  {
    title: '状态',
    key: 'status',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' },
  },
  {
    title: '操作',
    key: 'action',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' },
  },
];

import DateUtils from '@/common/DateUtils';
import UploadWidget from '../../../components/UploadWidget.vue';
// import Dashboard from '../Dashboard.vue';
const data = [];
export default {
  components: { UploadWidget },
  //   components: { Dashboard },
  data() {
    return {
      locationNames:["首页" ],
      loading: false,
      imageUrl: '',
      addOk: this.handleOk,
      visible: false,
      confirmLoading: false,

      banner: {cover:""},
      selectedRowKeys: [],
      selectedRowObj: [],
      data, //表格数据，从服务端中获取
      columns, //表头
      createdRange: ['', ''],
      updatedRange: ['', ''],
      publishDateRange: ['', ''],
      unPublishDateRange: ['', ''],
      searchForm: { searchText: '', status: '', startTime: '', endTime: '' }, //查询参数
      datapager: { pageSize: 10, total: 100, current: 1, showSizeChanger: true }, //分页参数
    };
  },
  created() {
    this.loadList();
  },
  methods: {
    /**读取列表 */
    async loadList() {
      //调用服务端接口获取列表
      let result = await this.axios({
        method: 'post',
        url: `banner/search/${this.datapager.current}/${this.datapager.pageSize}`,
        data: this.searchForm,
      });
      var datum = result.data.data;
      this.datapager.total = result.data.total;
      this.data = JSON.parse(JSON.stringify(datum));
    },
    /**确认查询 */
    searchSubmit(e) {
      this.searchForm.createStartTime = this.createdRange[0];
      this.searchForm.createEndTime = this.createdRange[1];
      this.searchForm.updateStartTime = this.updatedRange[0];
      this.searchForm.updateEndTime = this.updatedRange[1];
      this.searchForm.shelveStartTime = this.publishDateRange[0];
      this.searchForm.shelveEndTime = this.publishDateRange[1];
      this.searchForm.unshelveStartTime = this.unPublishDateRange[0];
      this.searchForm.unshelveEndTime = this.unPublishDateRange[1];
      this.loadList(); //重新读取列表
      e.preventDefault();
      // return false;
    },
    /**分页 */
    handleTableChange(pagination1, filters, sorter) {
      this.datapager = pagination1; //保存分页信息
      this.loadList(); //重新读取文章列表
    },
    /**多选 */
    onSelectChange(selectedRowKeys, obj) {
      this.selectedRowObj = obj;
      this.selectedRowKeys = selectedRowKeys;
    },
    locationHandleChange(value){
      this.banner.location = value;
    },
    /**新增 */
    showModal() {
      this.visible = true;
      this.addOk = this.handleOk;
    },
    /**新增确定 */
    async handleOk(e) {
      this.confirmLoading = true;
      try {
        let result = await this.axios({
          method: 'post',
          url: `banner/save`,
          data: this.banner,
        });
        this.$message.success('添加成功');
      } catch (error) {
        this.$message.error('添加失败');
      }
      setTimeout(() => {
        this.loadList();
        this.visible = false;
        this.confirmLoading = false;
        this.banner = {};
        this.imageUrl = '';
      }, 500);
    },
    /**新增取消 */
    handleCancel(e) {
      this.banner = {};
      this.imageUrl = '';
      this.visible = false;
    },
    /**修改 */
    showUpdate(obj) {
      this.visible = true;

      //深拷贝
      this.banner = JSON.parse(JSON.stringify(obj));
      this.imageUrl = this.banner.cover;
      this.addOk = this.update;
    },
    /**修改 */
    async update() {
      this.confirmLoading = true;

      try {
        let result = await this.axios({
          method: 'post',
          url: `banner/update`,
          data: this.banner,
        });
        this.$message.success('修改成功');
      } catch (error) {
        this.$message.error('修改失败');
      }
      setTimeout(() => {
        this.loadList();
        this.visible = false;
        this.confirmLoading = false;
        this.banner = {};
        this.imageUrl = '';
      }, 500);
    },

    /**删除 */
    del(obj) {
      var that = this;
      //删除提示
      this.$confirm({
        title: '提示',
        content: '是否确定删除',
        async onOk() {
          //确定删除
          try {
            let result = await that.axios.delete(`banner/delete/${obj.id}`);
            that.$message.success('删除成功');
            that.loadList();
          } catch (error) {
            that.$message.error('删除失败');
          }
        },
        onCancel() {}, //点击取消按钮后
      });
    },
    /**批量上架 */
    async shelve(record) {
      let bannersIds = this.selectedRowKeys;
      if (record.id != null) {
        bannersIds = [record.id];
      }
      try {
        let url = `banner/shelve`;
        let result = await this.axios.get(`banner/shelve?bannersIds=${bannersIds}`);
        this.loadList();
        this.$message.success('修改成功');
      } catch (error) {
        this.$message.error('修改失败');
      }
    },
    /**批量下架 */
    async unShelve(record) {
      let bannersIds = this.selectedRowKeys;
      if (record.id != null) {
        bannersIds = [record.id];
      }
      try {
        let result = await this.axios.get(`banner/unShelve?bannersIds=${bannersIds}`);
        this.loadList();
        this.$message.success('修改成功');
      } catch (error) {
        this.$message.error('修改失败');
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less">
.a {
  background-color: aquamarine;
}
</style>
